import styled from 'styled-components/macro';
import { device } from '../../../../styles/devices';

export const BalanceTableContainer = styled.div`
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
export const TableRowHead = styled.tr`
  border-top: 2px solid ${({ theme }) => theme.colors.grey.light};
  border-bottom: 2px solid ${({ theme }) => theme.colors.grey.light};
  background-color: ${({ theme }) => theme.colors.grey.light};
`;
export const TableRow = styled.tr`
  border-top: 2px solid ${({ theme }) => theme.colors.grey.light};
  border-bottom: 2px solid ${({ theme }) => theme.colors.grey.light};
`;

export const TableHead = styled.td`
  padding: 2px 2px 2px 2px;
  color: ${({ theme }) => theme.colors.white.default};
  font-size: ${({ theme }) => theme.font.size.smallest};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  @media ${device.mobileMed} {
    padding: 8px 8px 8px 6px;
    font-size: ${({ theme }) => theme.font.size.default};
  }
`;
export const TableData = styled.td`
  padding: 2px 2px 2px 2px;
  color: ${({ theme }) => theme.colors.primary.default};
  font-size: ${({ theme }) => theme.font.size.smallest};
  @media ${device.mobileMed} {
    padding: 8px 2px 8px 4px;
    font-size: ${({ theme }) => theme.font.size.default};
  }
`;
export const TableBody = styled.tbody``;
