import React from 'react';

import { Line, SectionContainer } from '../../../shared/Layout.styles';
import { Markdown } from '../../../shared/markdown';
import { successConstants, purchaseInstructions, expiryInstructions } from '../../../utils/constants/success';
import { useLocation } from 'react-router-dom';


export const Success = () => {
  const { subHeading, successText } = successConstants;
  const location: any = useLocation();

  return (
    <>
      <Line />
      <SectionContainer align="center">
        <Markdown children={subHeading} align="center" />
        <Markdown
          children={
            location?.state?.message ? location?.state?.message : successText
          }
          align="center"
          className="successText"
        />
          {process.env.REACT_APP_SHOW_CUSTOM_SUCCESS_MESSAGING !== 'null' ?
              <SuccessInstructions/>
               : null}
      </SectionContainer>
    </>
  );
};

const SuccessInstructions = () => {
    const {   pinInfoHeading, pinInfoText} = purchaseInstructions;
    const {   expiryInfoHeading, expiryInfoText} = expiryInstructions;
    return (
        <>
            <SectionContainer align="center">
                <Markdown children={pinInfoHeading} align="center" />
                <Markdown
                    children={
                        pinInfoText
                    }
                    align="center"
                    className="successText"
                />
                <Markdown children={expiryInfoHeading} align="center" />
                <Markdown
                    children={
                        expiryInfoText
                    }
                    align="center"
                    className="successText"
                />
            </SectionContainer>
        </>
    );
};
