import React from 'react';
import { activateCardConstants } from '../../../../utils/constants/activate';
import { Markdown } from '../../../../shared/markdown';
import { Controller, useFormContext } from 'react-hook-form';
import { CheckboxElement } from '../../../../shared/formElements/checkbox';
import Input from '../../../../shared/formElements/input';
import {DropdownElement} from "../../../../shared/formElements/dropdown/Dropdown";

export const ActivateCardInputs = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { termsConditionsText, defaultValue } = activateCardConstants;

  return (
    <>
      <Controller
        render={(props) => (
          <Input
            {...props}
            type="text"
            label="First Name*"
            fieldName="firstName"
            validation={errors?.firstName?.message}
            width="100%"
          />
        )}
        name="firstName"
        control={control}
        defaultValue={defaultValue.firstName || ''}
      />
      <Controller
        render={(props) => (
          <Input
            {...props}
            type="text"
            label="Last Name*"
            fieldName="lastName"
            validation={errors?.lastName?.message}
            width="100%"
          />
        )}
        name="lastName"
        control={control}
        defaultValue={defaultValue.lastName || ''}
      />
      <Controller
        render={(props) => (
          <Input
            {...props}
            type="text"
            label="Unique Card ID*"
            fieldName="activationCode"
            validation={errors?.activationCode?.message}
            tooltip="How to find my Unique Card ID?"
            tooltipLink="https://vaultps.zendesk.com/hc/en-au/articles/4416855429263-Where-do-I-find-my-card-EAID-"
            width="100%"
          />
        )}
        name="activationCode"
        control={control}
        defaultValue={defaultValue.activationCode || ''}
      />
      <Markdown
        children="This can be located on the top left corner on the back of your card. It is alphanumerical and 9 digits long."
        className="subText"
      />

        <Controller
            render={(props) => (
                <Input
                    {...props}
                    type="text"
                    label="Flight Number*"
                    fieldName="flightNumber"
                    validation={errors?.flightNumber?.message}
                    width="100%"
                />
            )}
            name="flightNumber"
            control={control}
            defaultValue=''
        />
        <Controller
            render={(props) => (
                <Input
                    {...props}
                    type="text"
                    label="Nationality*"
                    fieldName="nationality"
                    validation={errors?.nationality?.message}
                    width="100%"
                />
            )}
            name="nationality"
            control={control}
            defaultValue=''
        />
        <Controller
            render={(props) => (
                <DropdownElement
                    {...props}
                    fieldName={"travelPurpose"}
                    label="Travel Purpose - Leisure or Work*"
                    width="100%"
                    type="text"
                    validation={errors?.travelPurpose?.message}
                    options={['Leisure', 'Work']
                }
                />
            )}
            name="travelPurpose"
            control={control}
            defaultValue=''
        />

      <CheckboxElement
        name="termsConditionsCheck"
        label={termsConditionsText}
        className="termsConditionsText"
        control={control}
       validation={errors?.termsConditionsCheck?.message}
      />
    </>
  );
};
