export const successConstants = {
  subHeading: `# Your card has been activated`,
  successText: `#### ${process.env.REACT_APP_SUCCESS_SUBHEADING ? process.env.REACT_APP_SUCCESS_SUBHEADING : `Please note, funds may take up to 1 business day to be available on your card.`}`
};
export const purchaseInstructions = {
  pinInfoHeading: `### To make purchases`,
  pinInfoText: `#### ${process.env.REACT_APP_PURCHASE_INSTRUCTIONS ? process.env.REACT_APP_PURCHASE_INSTRUCTIONS : `Swipe your card in the eftpos terminal and select ‘savings’ and enter the PIN found on the reverse of the card.`}`
};
export const expiryInstructions = {
  expiryInfoHeading: `### Card expiry`,
  expiryInfoText: `#### ${process.env.REACT_APP_EXPIRY_INSTRUCTIONS ? process.env.REACT_APP_EXPIRY_INSTRUCTIONS : `Your card will expire 12 months from the date of activation. After this date any remaining funds will expire and be forfeited.`}`
}
