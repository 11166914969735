import styled from 'styled-components/macro';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popper from "@material-ui/core/Popper";
import {
    grey,
    fontFamily,
    mainFontFamily,
    inputBorder,
    inputBorderOnFocusColor,
    inputBorderOnHoverColor,
    inputTextColor,
} from '../../../styles/theme';
import {InputLabel} from "@material-ui/core";

export interface IInputContainerProps {
    width?: '25%' | '50%' | '75%' | '100%';
}

export const DropdownContainer = styled.div<IInputContainerProps>`
  padding: 10px 0;
  width: ${({ width }) =>
    width === '25%'
        ? '25%'
        : width === '50%'
            ? '50%'
            : width === '75%'
                ? '75%'
                : width === '100%'
                    ? '100%'
                    : 'inherit'};
  @media (max-width: 768px) {
    width: 100%;
  };
`;

export const DropdownSelect = styled(Select)`
  width: 100%;
  padding: 0px 10px 0px 10px;

  .MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.primary.default};
    font-family: ${mainFontFamily}, ${fontFamily};
  }

  .MuiInputBase-input {
    font-size: 14px;
    color: black;
  }
  .MuiInputLabel-outlined {
    font-size: 14px;
    transform: translate(15px, 15px);
    font-family: ${mainFontFamily}, ${fontFamily};
    color: ${grey};
  }
  .MuiOutlinedInput-notchedOutline {
    border-radius: 0;
    border-color: ${inputBorderOnFocusColor} !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${inputBorderOnFocusColor};
  }
  .MuiFormLabel-root.Mui-error {
    color: ${grey};
  }
  &:-webkit-autofill{
    WebkitBoxShadow: "0 0 0 1000px black inset"
  }
  &:hover {
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${inputBorderOnHoverColor};
    }
  }
  .MuiFormLabel-root.MuiOutlinedInput-input {
    color: ${inputBorderOnHoverColor};
  }
`;

export const InputAdornmentElement = styled(InputAdornment)`
  cursor: pointer;
  .help-icon: {
    z-index: 10000;
  }
  .MuiInputAdornment-positionEnd {
    cursor: pointer;
  }
`;

export const PopperElement = styled(Popper)`
  background-color: transparent;
`;

export const DropdownLabel = styled(InputLabel)`
  .MuiFormLabel-root.Mui-focused {
    color: ${inputBorderOnHoverColor} !important;
    font-family: ${mainFontFamily}, ${fontFamily};
  }

  .MuiInputBase-input {
    font-size: 14px;
    color: black;
  }
  .MuiOutlinedInput-input {
    padding: 14px;
    color: ${inputTextColor};
  }
  .MuiOutlinedInput-root.MuiInputLabel-outlined {
    font-size: 14px;
    transform: translate(15px, 15px);
    font-family: ${mainFontFamily}, ${fontFamily};
    color: ${grey};
  }
  .MuiOutlinedInput-root.MuiOutlinedInput-notchedOutline {
    border-radius: 0;
    border-color: ${inputBorder};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${inputBorderOnFocusColor};
  }
  .MuiFormLabel-root.Mui-error {
    color: ${grey};
  }
`;

