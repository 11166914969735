import React, {forwardRef, } from 'react';
import {FieldError, useFormContext} from 'react-hook-form';
import { Markdown } from '../../markdown';
import { DropdownLabel, DropdownSelect} from './Dropdown.styles';
import {FormControl,  MenuItem} from "@material-ui/core";
import {ControlledInputContainer} from "../controlledInput/ControlledInput.styles";

export interface IDropdownProps {
  label: string;
  validation?: string | FieldError | undefined;
  className?: string;
  fieldName: string;
  width?: '25%' | '50%' | '75%' | '100%';
  type?: string;
  options: string[];
}

export const DropdownElement = forwardRef<HTMLInputElement, IDropdownProps>(
    ({ fieldName, type, label, width, validation, className, options }, ref) => {
        const { setValue } = useFormContext();
        return (

            <ControlledInputContainer width={width}>
                <FormControl variant="outlined" fullWidth >
                    <DropdownLabel  htmlFor={fieldName} >{label}</DropdownLabel>
                    <DropdownSelect
                        id={fieldName}
                        label={label}
                        onChange={(e) => {
                            setValue(fieldName, e.target.value);
                        }}
                        type={type}
                        error={!!validation}
                        inputRef={ref}
                    >
                        {options.map((value, index) => {
                            return (
                                <MenuItem value={value} key={value}>
                                    {value}
                                </MenuItem>
                            );
                        })}
                    </DropdownSelect>
                </FormControl>

                {validation && (
                    <Markdown
                        children={validation.toString()}
                        className="validationText"
                    />
                )}
            </ControlledInputContainer>
        );
    }
);
